export const FB_PIXEL_ID = '877589840895016';

export const pageView = () => {
  if (window.fbq) {
    window.fbq('track', 'PageView');
  }
};

export const event = (name, options = {}) => {
  if (window.fbq) {
    window.fbq('track', name, options);
  }
};
