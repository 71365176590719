/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-nested-ternary */
import { forwardRef, ReactNode, useState, useEffect } from 'react';
import Script from 'next/script';

import { cn } from '@/utils/cn';
import { getAds } from '@/api/ads';
import { IAdsItem } from '@/api/ads/interfaces';
import { TagScript } from '@/api/tagscripts/interfaces';
import { BannerVariantType } from '../types';

import styles from './AdsContainer.module.css';

interface AdsContainerProps {
  children: ReactNode;
  variant: BannerVariantType;
  isPlaceholderVisible: boolean;
  className?: string;
  id?: number;
}

interface IAds {
  header: IAdsItem;
  footer: IAdsItem;
  sidebarTop: IAdsItem;
  sidebarBot: IAdsItem;
  posts: IAdsItem[];
}

export const AdsContainer = forwardRef<HTMLDivElement, AdsContainerProps>(
  ({ children, variant, isPlaceholderVisible, className, id = 0 }, ref) => {
    const defaultContainer = variant !== 'stickedBanner' && variant !== 'outstreamVideo' && styles.container;
    const stickyContainer = variant === 'stickedBanner' && styles.stickyContainer;
    const videoContainer = variant === 'outstreamVideo' && styles.videoContainer;
    const index = id ? id / 2 : 0;

    const [ads, setAds] = useState<IAds>();
    const [ad, setAd] = useState();

    useEffect(() => {
      const createAd = async () => {
        try {
          const AdsResponse = await getAds();
          const compareFn = (a: IAdsItem, b: IAdsItem) => a.attributes.order - b.attributes.order;

          const headers = AdsResponse.data
            .filter((adResponse) => adResponse.attributes.placement === 'Header')
            .sort(compareFn);
          const footers = AdsResponse.data
            .filter((adResponse) => adResponse.attributes.placement === 'Footer')
            .sort(compareFn);
          const sidebar = AdsResponse.data
            .filter((adResponse) => adResponse.attributes.placement === 'Right')
            .sort(compareFn);
          const posts = AdsResponse.data
            .filter((adResponse) => adResponse.attributes.placement === 'Posts')
            .sort(compareFn);

          setAds({
            header: headers[0],
            footer: footers[0],
            sidebarTop: sidebar[0],
            sidebarBot: sidebar[1],
            posts,
          });
        } catch (error) {
          console.error(error);
        }
      };
      void createAd();
    }, []);

    useEffect(() => {
      switch (variant) {
        case 'topNavbar':
          // (
          //   <a href={ads ? ads.header.attributes.destinationUrl : ''}>
          //     <picture>
          //       <source
          //         media="(min-width: 980px)"
          //         srcSet={ads ? ads.header.attributes.desktopImage.data.attributes.url : ''}
          //       />
          //       <img
          //         alt={ads ? ads.header.attributes.mobileImage.data.attributes.alternativeText : ''}
          //         src={ads ? ads.header.attributes.mobileImage.data.attributes.url : ''}
          //       />
          //     </picture>
          //   </a>,
          // );
          break;
        case 'belowPager':
          // (
          //   <a href={ads ? ads.footer.attributes.destinationUrl : ''}>
          //     <picture>
          //       <source
          //         media="(min-width: 980px)"
          //         srcSet={ads ? ads.footer.attributes.desktopImage.data.attributes.url : ''}
          //       />
          //       <img
          //         alt={ads ? ads.footer.attributes.mobileImage.data.attributes.alternativeText : ''}
          //         src={ads ? ads.footer.attributes.mobileImage.data.attributes.url : ''}
          //       />
          //     </picture>
          //   </a>,
          // );
          break;
        case 'sidebarTop':
          // (
          //   <a href={ads ? ads.sidebarTop.attributes.destinationUrl : ''}>
          //     <picture>
          //       <source
          //         media="(min-width: 980px)"
          //         srcSet={ads ? ads.sidebarTop.attributes.desktopImage.data.attributes.url : ''}
          //       />
          //       <img
          //         alt={ads ? ads.sidebarTop.attributes.mobileImage.data.attributes.alternativeText : ''}
          //         src={ads ? ads.sidebarTop.attributes.mobileImage.data.attributes.url : ''}
          //       />
          //     </picture>
          //   </a>,
          // );
          break;
        case 'sidebarBot':
          // (
          //   <a href={ads ? ads.sidebarBot.attributes.destinationUrl : ''}>
          //     <picture>
          //       <source
          //         media="(min-width: 980px)"
          //         srcSet={ads ? ads.sidebarBot.attributes.desktopImage.data.attributes.url : ''}
          //       />
          //       <img
          //         alt={ads ? ads.sidebarBot.attributes.mobileImage.data.attributes.alternativeText : ''}
          //         src={ads ? ads.sidebarBot.attributes.mobileImage.data.attributes.url : ''}
          //       />
          //     </picture>
          //   </a>,
          // );
          break;
        default:
          break;
      }
    }, [ads]);

    const createCustomTags = (tags: TagScript[], header?: boolean) => {
      return tags.map((tag) => {
        if (tag.attributes.tagType === 'favicon') return <link rel="icon" href={String(tag.attributes.tagSource)} />;
        if (tag.attributes.tagType === 'script') {
          if (header)
            return (
              <script
                id={tag.attributes.tagId ? tag.attributes.tagId : String(tag.id)}
                dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
                src={tag.attributes.tagSource ? tag.attributes.tagSource : undefined}
                async={tag.attributes.async ? tag.attributes.async : undefined}
                defer={tag.attributes.defer ? tag.attributes.defer : undefined}
                crossOrigin={tag.attributes.crossOrigin ? tag.attributes.crossOrigin : undefined}
              />
            );
          return (
            <Script
              id={tag.attributes.tagId ? tag.attributes.tagId : String(tag.id)}
              dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
              src={tag.attributes.tagSource ? tag.attributes.tagSource : undefined}
              async={tag.attributes.async ? tag.attributes.async : undefined}
              defer={tag.attributes.defer ? tag.attributes.defer : undefined}
              crossOrigin={tag.attributes.crossOrigin ? tag.attributes.crossOrigin : undefined}
            />
          );
        }
        return (
          <div
            className={tag.attributes.tagClass ? tag.attributes.tagClass : ''}
            id={tag.attributes.tagId ? tag.attributes.tagId : ''}
            dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
          />
        );
      });
    };

    const createInFeedTag = (tag: TagScript) => {
      if (tag.attributes.tagType === 'favicon') return <link rel="icon" href={String(tag.attributes.tagSource)} />;
      if (tag.attributes.tagType === 'script') {
        return (
          <Script
            id={tag.attributes.tagId ? tag.attributes.tagId : String(tag.id)}
            dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
            src={tag.attributes.tagSource ? tag.attributes.tagSource : undefined}
            async={tag.attributes.async ? tag.attributes.async : undefined}
            defer={tag.attributes.defer ? tag.attributes.defer : undefined}
            crossOrigin={tag.attributes.crossOrigin ? tag.attributes.crossOrigin : undefined}
          />
        );
      }
      if (tag.attributes.tagType === 'div') {
        return (
          <div
            className={tag.attributes.tagClass ? tag.attributes.tagClass : ''}
            id={tag.attributes.tagId ? tag.attributes.tagId : ''}
            dangerouslySetInnerHTML={{ __html: tag.attributes.Tag ? tag.attributes.Tag : '' }}
          />
        );
      }
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: String(tag.attributes.Tag),
          }}
        />
      );
    };

    return (
      <div className="flex flex-col items-center w-full relative">
        <div
          ref={ref}
          className={cn(
            'flex flex-col items-center',
            defaultContainer,
            stickyContainer,
            videoContainer,
            styles[variant],
            !isPlaceholderVisible && '!w-fit',
            isPlaceholderVisible && 'w-full',
            variant !== 'stickedBanner' && 'p-4 pt-6',
            className,
          )}
        >
          {variant === 'topNavbar' && ads?.header.attributes.tag_source_codes.data.length ? (
            // eslint-disable-next-line react/no-danger
            createCustomTags(ads.header.attributes.tag_source_codes.data, true)
          ) : variant === 'belowPager' && ads?.footer.attributes.tag_source_codes.data.length ? (
            // eslint-disable-next-line react/no-danger
            createCustomTags(ads.footer.attributes.tag_source_codes.data)
          ) : variant === 'sidebarTop' && ads?.sidebarTop.attributes.tag_source_codes.data.length ? (
            // eslint-disable-next-line react/no-danger
            createCustomTags(ads.sidebarTop.attributes.tag_source_codes.data)
          ) : variant === 'sidebarBot' && ads?.sidebarBot.attributes.tag_source_codes.data.length ? (
            // eslint-disable-next-line react/no-danger
            createCustomTags(ads.sidebarBot.attributes.tag_source_codes.data)
          ) : (variant === 'inFeed' || variant === 'outstreamVideo') &&
            ads?.posts[index >= ads.posts.length ? 0 : index].attributes.tag_source_codes.data.length ? (
            createInFeedTag(ads.posts[index >= ads.posts.length ? 0 : index].attributes.tag_source_codes.data[0])
          ) : variant === 'topNavbar' ? (
            <a href={ads ? ads.header.attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={ads ? ads.header.attributes.desktopImage.data.attributes.url : ''}
                />
                <img
                  alt={ads ? ads.header.attributes.mobileImage.data.attributes.alternativeText : ''}
                  src={ads ? ads.header.attributes.mobileImage.data.attributes.url : ''}
                />
              </picture>
            </a>
          ) : variant === 'belowPager' ? (
            <a href={ads ? ads.footer.attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={ads ? ads.footer.attributes.desktopImage.data.attributes.url : ''}
                />
                <img
                  alt={ads ? ads.footer.attributes.mobileImage.data.attributes.alternativeText : ''}
                  src={ads ? ads.footer.attributes.mobileImage.data.attributes.url : ''}
                />
              </picture>
            </a>
          ) : variant === 'sidebarTop' ? (
            <a href={ads ? ads.sidebarTop.attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={ads ? ads.sidebarTop.attributes.desktopImage.data.attributes.url : ''}
                />
                <img
                  alt={ads ? ads.sidebarTop.attributes.mobileImage.data.attributes.alternativeText : ''}
                  src={ads ? ads.sidebarTop.attributes.mobileImage.data.attributes.url : ''}
                />
              </picture>
            </a>
          ) : variant === 'sidebarBot' ? (
            <a href={ads ? ads.sidebarBot.attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={ads ? ads.sidebarBot.attributes.desktopImage.data.attributes.url : ''}
                />
                <img
                  alt={ads ? ads.sidebarBot.attributes.mobileImage.data.attributes.alternativeText : ''}
                  src={ads ? ads.sidebarBot.attributes.mobileImage.data.attributes.url : ''}
                />
              </picture>
            </a>
          ) : variant === 'inFeed' ? (
            <a href={ads ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.desktopImage.data.attributes.url
                      : ''
                  }
                />
                <img
                  alt={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.mobileImage.data.attributes
                          .alternativeText
                      : ''
                  }
                  src={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.mobileImage.data.attributes.url
                      : ''
                  }
                />
              </picture>
            </a>
          ) : variant === 'outstreamVideo' ? (
            <a href={ads ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.destinationUrl : ''}>
              <picture>
                <source
                  media="(min-width: 980px)"
                  srcSet={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.desktopImage.data.attributes.url
                      : ''
                  }
                />
                <img
                  alt={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.mobileImage.data.attributes
                          .alternativeText
                      : ''
                  }
                  src={
                    ads
                      ? ads.posts[index >= ads.posts.length ? 0 : index].attributes.mobileImage.data.attributes.url
                      : ''
                  }
                />
              </picture>
            </a>
          ) : (
            children
          )}
        </div>
      </div>
    );
  },
);
