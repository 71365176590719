interface ISpinnerProps {
  appLink: string;
  closeFn: () => void;
}

export const Ribbon = ({ appLink, closeFn }: ISpinnerProps) => (
  <div className="w-15/16 relative text-white flex justify-between items-center mx-auto p-[10px] rounded-[10px] bg-black mt-3 md:hidden">
    <img src="/images/favicon.png" alt="Aplikacja Fangol" width="38" height="38" className="shrink-0" />
    <div className="leading-none">
      <strong className="block text-xs">Nasza nowa aplikacja</strong>
      <span className="text-9">Korzystaj z nowych funkcji</span>
    </div>
    <a
      href={appLink}
      className="bg-primary-600 text-white font-medium h-fit rounded-md px-3 py-2 md:px-5 md:py-2.5 marker:focus:ring-2 focus:ring-primary-300 focus:outline-none hover:bg-primary-700 focus:bg-primary-700 !text-black text-xs md:text-sm"
    >
      Pobierz
    </a>
    <a
      href="#close"
      onClick={closeFn}
      className="absolute -right-[7px] -top-[7px] w-[15px] h-[15px] rounded-full bg-lime-100 flex justify-center items-center"
    >
      <img src="/images/close.png" alt="x" />
    </a>
  </div>
);
